
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import { Role } from '../../../api/types';
import SnackbarModule from '../../../store/modules/SnackbarModule';
import { DEFAULT_PASSWORD, getRandomInt } from '../../../utils/common';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
    grade: { required },
  },
  components: {},
})
export default class TeacherDashboardAddAccount extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() items!: string[];
  @Prop() isTherapyAdmin!: boolean;

  firstname = '';
  lastname = '';
  cEmail = '';
  grade = null;
  loading = false;

  get email() : string {
    return this.cEmail.trim();
  }

  set email(value: string) {
    this.cEmail = value;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const teacherUsername = `${this.firstname}${
          this.lastname
        }_${getRandomInt(1000)}_${this.schoolId}`;

        await this.schoolModule.addUser({
          username: teacherUsername,
          firstname: this.firstname,
          lastname: this.lastname,
          grade: this.grade ?? '',
          email: this.email,
          schoolId: parseInt(this.schoolId.toString()),
          role: Role.teacher,
          password: DEFAULT_PASSWORD,
        });
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.created.success').toString()
            : this.$i18n.t('snackbar.teachers.created.success').toString(),
          isSuccess: true,
        });
        this.clear();
        this.$emit('addedTeacher');
      } catch (error: any) {
        if (error?.response.status === 409) {
          this.snackbarModule.showMessage({
            message: this.isTherapyAdmin
              ? this.$i18n.t('snackbar.therapists.created.errorMail').toString()
              : this.$i18n.t('snackbar.teachers.created.errorMail').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.isTherapyAdmin
              ? this.$i18n.t('snackbar.therapists.created.error').toString()
              : this.$i18n.t('snackbar.teachers.created.error').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.firstname = '';
    this.lastname = '';
    this.cEmail = '';
    this.grade = null;
  }

  get selectErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Klasse auswählen.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('E-Mail angeben.');
    return errors;
  }
}
