
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class GeneralInfoPanels extends Vue {
  @Prop({ default: false }) isTherapist!: boolean;

  get keys(): number {
    return this.isTherapist ? 8 : 9;
  }

  get title(): string {
    return this.isTherapist ? 'therapist' : 'teacher';
  }
}
