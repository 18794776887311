
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types/api';

@Component
export default class EmailDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;
  @Prop() isTherapist!: boolean;

  get title(): string {
    return this.isTherapist ? 'therapist' : 'teacher';
  }

  sendEmail(): void {
    this.$emit('onSendEmail', this.user);
  }

  close(): void {
    this.$emit('onCloseEmail');
  }
}
