
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '@/api/types';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component({
  components: { ClassListTemplate },
})
export default class PrintClassListButton extends Vue {
  @Prop() students!: UserDto[];
  @Prop() grade!: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  progress = 0;
  loading = false;

  async generatePdf(): Promise<void> {
    this.loading = true;
    try {
      await this.schoolModule.getClasslist(`Zugangsliste.pdf`);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.error').toString(),
        isSuccess: false,
      });
    }
    this.loading = false;
  }
}
