
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OpenImportButton extends Vue {
  @Emit()
  openImport() {
    console.log();
  }
}
