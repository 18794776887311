
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CsvImportStudents from './CsvImportStudents.vue';
import { UserDto } from '@/api/types';

@Component({
  components: {
    CsvImportStudents,
  },
})
export default class ImportDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() ids!: number[];
  @Prop() isTherapist!: boolean;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  students: UserDto[] = [];
  snackbar = false;
  isSuccess = false;
  message = '';

  get teacher(): UserDto {
    return this.userModule.currentUser;
  }

  get grade(): string {
    return this.teacher.grade!;
  }

  get myStudents(): UserDto[] {
    return this.students;
  }

  @Emit()
  close(): void {
    console.log();
  }

  @Emit()
  imported(): void {
    this.close();
  }
}
