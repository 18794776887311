
import ClassLevelSelect from './ClassLevelSelect.vue';
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { UserDto } from '@/api/types';

@Component({
  components: { ClassListTemplate, ClassLevelSelect },
})
export default class SetClassLevel extends Vue {
  @Prop() students!: UserDto[];

  @Emit()
  updatedLevel() {
    console.log();
  }
}
