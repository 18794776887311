
import AdminTable from './table/AdminTable.vue';
import router from '@/router';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, UserDto } from '../../../api/types/api';
import AdminButton from '../base/AdminButton.vue';
import AdminSnackbar from '../base/AdminSnackbar.vue';
import TeachersTable from './table/TeachersTable.vue';
import TeacherDashboardAddAccount from './TeacherDashboardAddAccount.vue';
import TeacherDashboardTherapistAddAccount from './TeacherDashboardTherapistAddAccount.vue';
import { getSchoolPrefix } from '@/utils/common';

@Component({
  components: {
    TeacherDashboardAddAccount,
    TeachersTable,
    AdminSnackbar,
    TeacherDashboardTherapistAddAccount,
    AdminButton,
    AdminTable,
  },
})
export default class TeacherDashboard extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  teachers: UserDto[] = [];
  class = '';

  get classes(): string[] {
    return this.schoolModule.classes ?? [];
  }

  get grade(): string {
    return this.class;
  }

  get isTherapyOrParentAdmin(): boolean {
    return (
      this.schoolModule.clientType === Client.therapy ||
      this.schoolModule.clientType === Client.parent
    );
  }

  async created(): Promise<void> {
    this.teachers = await this.schoolModule.getTeachersOrTherapists();
    this.class = this.userModule.currentUser.grade ?? '';
  }

  async fetchTeachers(): Promise<void> {
    this.teachers = await this.schoolModule.getTeachersOrTherapists();
  }

  goToStudentsTeachers(): void {
    router.push({
      name: 'students',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
