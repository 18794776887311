
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Client, Role, UpdateUserDto, UserDto } from '../../../../api/types';
import SnackbarModule from '../../../../store/modules/SnackbarModule';
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import DeleteDialog from './DeleteDialog.vue';
import EditDialog from './EditDialog.vue';
import EditDialogTherapyAdmin from './EditDialogTherapyAdmin.vue';
import EmailDialog from './EmailDialog.vue';

@Component({
  components: {
    EditDialog,
    AdminSnackbar,
    DeleteDialog,
    EmailDialog,
    EditDialogTherapyAdmin,
  },
})
export default class TeachersTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() users!: UserDto[];
  @Prop() classes!: string[];
  @Prop() isTherapyAdmin!: boolean;
  @Prop({ default: false }) isAdmin?: boolean;

  search = '';
  loadEdit = false;
  loadDelete = false;
  loadEmail = false;
  dialogEdit = false;
  dialogDelete = false;
  dialogEmail = false;
  editedIndex = -1;
  editedItem: UserDto = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    grade: '',
    role: Role.teacher,
    schoolId: 0,
    doneA: false,
    doneB: false,
    isMiddleschool: false,
    isTutorialOnly: false,
  };
  defaultItem: UserDto = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    grade: '',
    role: Role.teacher,
    schoolId: 0,
    doneA: false,
    doneB: false,
    isMiddleschool: false,
    isTutorialOnly: false,
  };
  headers = this.isTherapyAdmin
    ? [
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ]
    : [
        { text: 'Klasse', value: 'grade', align: 'center' },
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ];

  get filteredUsers(): UserDto[] {
    const me = this.userModule.currentUser;
    if (this.isAdmin) {
      return this.users.filter((user) => user._id === me._id);
    } else {
      return this.users.filter((user) => user._id !== me._id);
    }
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  cannotDelete(id: string): boolean {
    return this.userModule.currentUser._id === id;
  }

  editItem(user: UserDto): void {
    this.editedIndex = this.users.indexOf(user);
    this.editedItem = user;
    this.dialogEdit = true;
  }

  deleteItem(user: UserDto): void {
    this.editedItem = user;
    this.editedIndex = this.users.indexOf(user);
    this.dialogDelete = true;
  }

  openEmail(user: UserDto): void {
    this.editedItem = user;
    this.dialogEmail = true;
  }

  async deleteItemConfirm(user: UserDto): Promise<void> {
    try {
      this.loadDelete = true;
      await this.schoolModule.deleteUser(user._id);
      this.snackbarModule.showMessage({
        message: this.isParent
          ? this.$i18n.t('snackbar.parents.delete.success').toString()
          : this.isTherapyAdmin
          ? this.$i18n.t('snackbar.therapists.delete.success').toString()
          : this.$i18n.t('snackbar.teachers.delete.success').toString(),
        isSuccess: true,
      });
      this.users.splice(this.editedIndex, 1);
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.isParent
          ? this.$i18n.t('snackbar.parents.delete.error').toString()
          : this.isTherapyAdmin
          ? this.$i18n.t('snackbar.therapists.delete.error').toString()
          : this.$i18n.t('snackbar.teachers.delete.error').toString(),
        isSuccess: false,
      });
    }
    this.loadDelete = false;
    this.closeDelete();
  }

  closeEmail(): void {
    this.dialogEmail = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  close(): void {
    this.dialogEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  async save(user: UpdateUserDto): Promise<void> {
    try {
      this.loadEdit = true;
      await this.schoolModule.updateUser({
        id: this.editedItem._id,
        updateUser: user,
      });

      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        console.log();
      }
      this.snackbarModule.showMessage({
        message: this.isParent
          ? this.$i18n.t('snackbar.parents.edited.success').toString()
          : this.isTherapyAdmin
          ? this.$i18n.t('snackbar.therapists.edited.success').toString()
          : this.$i18n.t('snackbar.teachers.edited.success').toString(),
        isSuccess: true,
      });
    } catch (error: any) {
      if (error.response?.status == 409) {
        this.snackbarModule.showMessage({
          message: this.isParent
            ? this.$i18n.t('snackbar.parents.edited.errorMail').toString()
            : this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.errorMail').toString()
            : this.$i18n.t('snackbar.teachers.edited.errorMail').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.error').toString()
            : this.isParent
            ? this.$i18n.t('snackbar.teachers.edited.error').toString()
            : this.$i18n.t('snackbar.teachers.edited.error').toString(),
          isSuccess: false,
        });
      }
    }
    this.loadEdit = false;
    this.editedTeacher();
    this.close();
  }

  async sendEmail(user: UserDto): Promise<void> {
    this.loadEmail = true;
    try {
      if (!user.email) throw new Error('Keine Email-Adresse vorhanden');
      await this.schoolModule.sendPasswordMail(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.error').toString(),
        isSuccess: false,
      });
    }
    this.dialogEmail = false;
    this.loadEmail = false;
  }

  @Emit()
  editedTeacher(): void {
    console.log();
  }
}
