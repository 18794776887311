
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  minLength,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  Role,
  UpdateUserDto,
  UserDto,
} from '../../../../api/types/api';
import UserModule from '../../../../store/modules/UserModule';
import SchoolModule from '../../../../store/modules/SchoolModule';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    studentId: { required, numeric, minValue: minValue(1) },
    grade: { required },
  },
})
export default class EditDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;
  // @Prop() grade!: string;
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  // get level(): number | null {
  //   return this.user.level ? +this.user.level : null;
  // }

  // set level(value: number | null) {
  //   if (value) {
  //     this.user.level = value;
  //   }
  // }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get classes(): string[] {
    return this.schoolModule.classes!;
  }

  get studentId(): number | null {
    return this.user.studentId ?? null;
  }

  get firstname(): string {
    return this.user.firstname;
  }

  get lastname(): string {
    return this.user.lastname;
  }

  get password(): string {
    return this.user.studentPassword!;
  }

  get grade(): string {
    return this.user.grade ?? '';
  }

  get level(): number | null {
    return this.user.level ? +this.user.level : null;
  }

  items = [
    { value: '2', text: '2.Schulstufe' },
    { value: '3', text: '3.Schulstufe' },
    { value: '4', text: '4.Schulstufe' },
    { value: '5', text: '5.Schulstufe' },
    { value: '6', text: '6.Schulstufe' },
  ];

  save(): void {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      const user: UpdateUserDto = {
        grade: this.grade,
        //INFO strange bug: is number but backend received string => extra casting
        studentId: parseInt(this.studentId!.toString()),
        firstname: this.firstname,
        lastname: this.lastname,
        level: this.isSchool && this.level ? +this.level : undefined,
        // studentPassword: this.password,
        // password: this.password,
      };
      this.$emit('onSave', user);
    } else {
      console.log('invalid');
    }
  }

  close(): void {
    this.$emit('onClose');
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.password.required && errors.push('Bitte Passwort angeben.');
    return errors;
  }
  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Bitte Schulstufe angeben.');
    return errors;
  }
}
