
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types/api';

@Component
export default class DeleteDialog extends Vue {
  @Prop() dialogDelete!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;

  deleteUser(): void {
    this.$emit('onDelete', this.user);
  }

  close(): void {
    this.$emit('onCloseDelete');
  }
}
