
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import { Prop } from 'vue-property-decorator';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';

@Component({
  components: { ClassListTemplate },
})
export default class PrintClassEvaluationButton extends Vue {
  @Prop() form!: string;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  loading = false;

  async generatePdf(): Promise<void> {
    try {
      this.loading = true;
      await this.userModule.getClassEvaluation(this.form);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.code.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      if (this.schoolModule.hasMissingLevelInStudents) {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('classLevel.many-error').toString(),
          isSuccess: false,
          time: 6000,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.code.error').toString(),
          isSuccess: false,
        });
      }
    }
    this.loading = false;
  }
}
