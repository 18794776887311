
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { UpdateUserDto, UserDto } from '../../../../api/types/api';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
  },
})
export default class EditDialogTherapyAdmin extends Vue {
  @Prop() dialog!: boolean;
  @Prop() user!: UserDto;
  @Prop() classes?: string[];
  @Prop() loading!: boolean;
  @Prop() title!: string;

  get firstname(): string {
    return this.user.firstname;
  }

  get lastname(): string {
    return this.user.lastname;
  }

  get email(): string {
    return this.user.email!;
  }

  get userTerm(): string {
    return 'Therapeut/in';
  }

  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const user: UpdateUserDto = {
        grade: '',
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
      };
      this.$emit('onSave', user);
    }
  }

  close(): void {
    this.$emit('onClose');
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }
}
