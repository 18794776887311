
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  email,
  minLength,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { UpdateUserDto, UserDto } from '../../../../api/types/api';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
    grade: {
      required: requiredIf(function (this: any) {
        return !this.isAdmin;
      }),
    },
  },
})
export default class EditDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() user!: UserDto;
  @Prop() classes?: string[];
  @Prop() loading!: boolean;
  @Prop() isTherapyAdmin!: boolean;
  @Prop() isAdmin!: boolean;

  get grade(): string {
    return this.user.grade!;
  }

  get firstname(): string {
    return this.user.firstname;
  }

  get lastname(): string {
    return this.user.lastname;
  }

  get email(): string {
    return this.user.email!;
  }

  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const user: UpdateUserDto = {
        grade: this.grade,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
      };
      this.$emit('onSave', user);
    }
  }

  close(): void {
    this.$emit('onClose');
  }

  get selectErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Bitte Klasse auswählen.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }
}
