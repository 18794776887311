
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import EvaluationTemplate from '../pdf-templates/EvaluationTemplate.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { getModule } from 'vuex-module-decorators';
import { Prop, Emit } from 'vue-property-decorator';
import UserModule from '@/store/modules/UserModule';

@Component({
  components: { EvaluationTemplate, AdminSnackbar },
})
export default class EnableFormSwitch extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() id!: string;
  @Prop() enabledForm!: boolean;
  @Prop() form!: string;

  loading = false;
  enabled: boolean | null = null;

  mounted() {
    this.enabled = this.enabledForm;
  }

  async toggleFormSwich() {
    try {
      this.loading = true;
      await this.schoolModule.updateUser({
        id: this.id,
        updateUser: {
          [`enable${this.form.toUpperCase()}`]: this.enabled,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggled() {}

  // showSnackbar(data: SnackbarData) {
  //   this.snackbar = true;
  //   this.isSuccess = data.isSuccess;
  //   this.message = data.message;
  //   setTimeout(async () => {
  //     this.snackbar = false;
  //   }, 2500);
  // }
}
